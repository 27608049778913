import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_@playwright+test@1.42.1_react-dom@19.0.0-rc-fb9a90fa48-2024061_eetknuyikvsgnn24zl36e32x7u/node_modules/next/dist/client/components/render-from-template-context.js");
